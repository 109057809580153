import _toPropertyKey from "./toPropertyKey.js";
var exports = {};
var toPropertyKey = _toPropertyKey;
function _defineProperties(e, r) {
  for (var t = 0; t < r.length; t++) {
    var o = r[t];
    o.enumerable = o.enumerable || !1, o.configurable = !0, "value" in o && (o.writable = !0), Object.defineProperty(e, toPropertyKey(o.key), o);
  }
}
function _createClass(e, r, t) {
  return r && _defineProperties(e.prototype, r), t && _defineProperties(e, t), Object.defineProperty(e, "prototype", {
    writable: !1
  }), e;
}
exports = _createClass, exports.__esModule = true, exports["default"] = exports;
export default exports;
export const __esModule = exports.__esModule;